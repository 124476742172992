import React from 'react';
import { render } from 'react-dom';
import { Router, Redirect } from '@reach/router';
import {
  HSCEDashboardPage,
  HSCEFundPage,
  HSCEBalanceNewPage,
  HSCEBalanceListPage,
  HSCEBalancePage,
  HSCEBalanceEditPage,
  HSCERiskPage,
  BondBalanceListPage,
  BondBalanceNewPage,
  BondBalancePage,
  BondBalanceEditPage,
  BondTaskListPage,
  BondTaskNewPage,
  BondTaskEditPage,
  BondTaskPage,
  BondFundPage
} from 'pages';
import App from '../web/App';
import AdminRouter from '../web/AdminRouter';
import config from 'config';

config.auth_logo = require('images/logo-400.png');
config.footer = '铸锋资产';

const Root = () => {
  return (
    <App>
      <Router>
        <Redirect from="/login" to="/" noThrow />
        <Redirect from="/hsce" to="/" noThrow />
        <BondBalanceListPage path="/" />
        <HSCERiskPage path="/risk" />
        <HSCEFundPage path="/hsce/:name" />

        <HSCEBalanceNewPage path="/balance/new" />
        <HSCEBalanceListPage path="/balance" />
        <HSCEBalancePage path="/balance/:id" />
        <HSCEBalanceEditPage path="/balance/:id/edit" />

        <Redirect from="/bond" to="/bond/funds/SK7616" noThrow />
        <BondTaskListPage path="/bond/tasks" />
        <BondTaskNewPage path="/bond/tasks/new" />
        <BondTaskEditPage path="/bond/tasks/:id/edit" />
        <BondTaskPage path="/bond/tasks/:id" />
        <BondFundPage path="/bond/funds/:name" />

        <BondBalanceListPage path="/bond/balance" />
        <BondBalancePage path="/bond/balance/:id" />
        <BondBalanceEditPage path="/bond/balance/:id/edit" />
        <BondBalanceNewPage path="/bond/balance/new" />

        <AdminRouter path="*" />
      </Router>
    </App>
  );
};

render(<Root />, document.getElementById('root'));
